var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-tenant","title":this.i18nT("Ajouter un locataire"),"ok-only":"","ok-title":_vm.i18nT("Sauvegarder"),"no-close-on-backdrop":"","size":"lg"},on:{"ok":_vm.saveNewTenant,"hidden":_vm.resetForm,"shown":_vm.getTenants}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-card',{staticClass:"p-1",attrs:{"no-body":""}},[_c('b-row',[_c('b-alert',{staticClass:"ml-1",attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.i18nT("Info" ))+" ")]),_vm._v(" "+_vm._s(_vm.i18nT("Créer un nouveau, ou choisir un déjà existant."))+" ")],1)])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.i18nT("Locataire")}},[_c('v-select',{attrs:{"options":_vm.availableTenants,"label":"text","clearable":false,"selectable":function (selected) { return selected.disabled !== true; }},on:{"input":function($event){return _vm.updateTenant(_vm.availableTenant)}},model:{value:(_vm.availableTenant),callback:function ($$v) {_vm.availableTenant=$$v},expression:"availableTenant"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.i18nT("Type")}},[_c('v-select',{attrs:{"options":_vm.tenantTypes,"label":"text","reduce":function (item){ return item.value; },"clearable":false,"placeholder":_vm.i18nT("Choisir")},model:{value:(_vm.newTenant.TenantType),callback:function ($$v) {_vm.$set(_vm.newTenant, "TenantType", $$v)},expression:"newTenant.TenantType"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.i18nT("Prénom")}},[_c('validation-provider',{attrs:{"name":_vm.i18nT("Prénom"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.i18nT("Prénom"),"state":errors.length > 0 ? false:null},model:{value:(_vm.newTenant.TenantFirstName),callback:function ($$v) {_vm.$set(_vm.newTenant, "TenantFirstName", $$v)},expression:"newTenant.TenantFirstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.i18nT("Nom")}},[_c('validation-provider',{attrs:{"name":_vm.i18nT("Nom"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.i18nT("Nom"),"state":errors.length > 0 ? false:null},model:{value:(_vm.newTenant.TenantLastName),callback:function ($$v) {_vm.$set(_vm.newTenant, "TenantLastName", $$v)},expression:"newTenant.TenantLastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.i18nT("E-Mail")}},[_c('validation-provider',{attrs:{"name":"contact-email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.i18nT("email@domain.com"),"id":"contact-email","state":errors.length > 0 ? false:null},model:{value:(_vm.newTenant.TenantEmail),callback:function ($$v) {_vm.$set(_vm.newTenant, "TenantEmail", $$v)},expression:"newTenant.TenantEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.i18nT("Mobile")}},[_c('validation-provider',{attrs:{"name":"contact-phone","rules":{ validPhoneNumber: _vm.mazphoneObj }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MazPhoneNumberInput',{key:_vm.phoneReady,attrs:{"default-country-code":"FR","placeholder":_vm.i18nT("Mobile"),"id":"Phone","translations":{
                                    countrySelectorLabel: _vm.i18nT("Country code"),
                                    countrySelectorError: _vm.i18nT("Select a country"),
                                    phoneNumberLabel: _vm.i18nT("Mobile phone number"),
                                    example: _vm.i18nT("Example:"),
                                     }},on:{"update":_vm.updatePhoneObj},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.i18nT(errors[0])))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }