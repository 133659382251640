<template>
    <b-modal
      id="modal-add-tenant"
      :title="this.i18nT(`Ajouter un locataire`)"
      ok-only
      :ok-title="i18nT(`Sauvegarder`)"
      no-close-on-backdrop
      @ok="saveNewTenant"
      @hidden="resetForm"
      @shown="getTenants"
      size="lg"
    >
        <validation-observer ref="simpleRules">
            <b-form>
                <b-card no-body class="p-1">
                    <b-row>
                        <b-alert
                            class="ml-1"
                            variant="primary"
                            show
                            >
                            <div class="alert-body">
                                <span>
                                    <b-badge variant="primary">
                                      {{ i18nT(`Info` ) }}
                                    </b-badge>
                                     {{i18nT(`Créer un nouveau, ou choisir un déjà existant.`)}}
                                </span>
                            </div>
                        </b-alert>
                    </b-row>
                    <b-row>
                        <b-col cols="12"
                               md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Locataire`)"
                            >
                                <v-select
                                    v-model="availableTenant"
                                    :options="availableTenants"
                                    label="text"
                                    :clearable="false"
                                    @input="updateTenant(availableTenant)"
                                    :selectable="selected => selected.disabled !== true"
                                />
                            </b-form-group>
                        </b-col>
                            <b-col cols="12"
                                   md="6"
                                 >
                                <b-form-group
                                    :label="i18nT(`Type`)"
                                    >
                                    <v-select
                                        v-model="newTenant.TenantType"
                                        :options="tenantTypes"
                                        label="text"
                                        :reduce="(item)=>item.value"
                                        :clearable="false"
                                        :placeholder="i18nT(`Choisir`)"
                                    />
                                </b-form-group>
                            </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Prénom`)"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Prénom`)"
                                    rules="required"
                                >
                                    <b-form-input
                                        :placeholder="i18nT(`Prénom`)"
                                        v-model="newTenant.TenantFirstName"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Nom`)"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Nom`)"
                                    rules="required"
                                >
                                    <b-form-input
                                        :placeholder="i18nT(`Nom`)"
                                        v-model="newTenant.TenantLastName"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="6"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`E-Mail`)"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="contact-email"
                                    rules="email"
                                >
                                    <b-form-input
                                        :placeholder="i18nT(`email@domain.com`)"
                                        v-model="newTenant.TenantEmail"
                                        id="contact-email"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="6"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Mobile`)"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="contact-phone"
                                    :rules="{ validPhoneNumber: mazphoneObj }"
                                >
                                    <MazPhoneNumberInput
                                        :key="phoneReady"
                                        default-country-code="FR"
                                        :placeholder="i18nT(`Mobile`)"
                                        v-model="phone"
                                        @update="updatePhoneObj"
                                        id="Phone"
                                        :translations="{
                                        countrySelectorLabel: i18nT(`Country code`),
                                        countrySelectorError: i18nT(`Select a country`),
                                        phoneNumberLabel: i18nT(`Mobile phone number`),
                                        example: i18nT(`Example:`),
                                         }"
                                    />
                                    <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>

import {
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BModal,
  BCard,
  BRow,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import { required, email} from '@validations';
import DatePicker from "@core/components/DatePicker.vue";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { MazBtn,MazPhoneNumberInput } from 'maz-ui'
import useAuth from "@/auth/useAuth";



extend('validPhoneNumber', {
  params: ['phoneNumberData'],
  validate: (value, maz) => {
    if(maz.phoneNumberData){
      return maz.phoneNumberData.isValid ;
    }else {
      return false;
    }
  },
  message: `Invalid phone number`,
});
// TODO check dateFormat and follow on date formatting across the app
extend('date_format', {
  validate(value) {
    return {
      required: true,
      valid: moment(value, this.dateFormat).isValid() && value.length === 10 && moment(value).isBetween('1900-01-01', '2999-12-31')
    };
  },
  message: `Invalid date.`,
})

export default {
    components: {
        DatePicker,
        BFormInput,
        BRow,
        ValidationProvider,
        ValidationObserver,
        BCol,
        BForm,
        BCard,
        BFormGroup,
        vSelect,
        BModal,
        MazBtn,
        MazPhoneNumberInput,
    },
    props: {
        tenant: {
            type: Object,
            default: null
        },
    },
    data(){
        return {
            newTenant: {},
            availableTenants: [],
            availableTenant: {
                value: '-1',
                text: this.i18nT(`Choisir`),
            },
            tenantTypes: [
                {
                    value: 'person',
                    text: this.i18nT(`Particulier`),
                },
                {
                    value: 'company',
                    text: this.i18nT(`Company`),
                },
            ],
            tenantType: {
                value: '-1',
                text: this.i18nT(`Choisir`),
            },
            mazphoneObj: {},
            required,
            email,
            phoneReady: 'no',
            phone: '',

        }
    },
    computed: {
        dateFormat() {
            return useAuth.getDateFormat() != 'null'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        },
    },
    methods:{
        getTenants(){

            if(this.availableTenants.length > 0){
                return;
            }

            this.$http.get('landlord/tenants?active=1',{showLoader: false})
                .then(({data}) => {
                    console.log(data);
                    this.availableTenants = data.data.tenants.map(tenant => {
                        return {
                            value: tenant.TenantID,
                            text: `${tenant.TenantFirstName} ${tenant.TenantLastName}`,
                            disabled: false,
                            tenant
                        }
                    });
                    this.availableTenants.unshift({
                        value: '-1',
                        text: `== ${this.i18nT(`LOCATAIRES`)} ==`,
                        disabled: true,
                    });

                    this.availableTenants.unshift({
                        value: 'new',
                        text: this.i18nT(`Ajouter un nouveau`),
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updateTenant(val){

            if(val.value === 'new'){
                this.newTenant = {};
                this.phone = '';
                this.phoneReady = 'yes';
                return;
            }

            this.phoneReady = 'no';
            this.newTenant = val.tenant;
            this.phone = '';
            this.$nextTick(() => {
                this.phone = val.tenant.TenantMobilePhone;
                this.phoneReady = 'yes';
            });

        },
        updatePhoneObj(dataCollected){
            this.mazphoneObj = dataCollected;
        },
        saveNewTenant(){

            //TODO - add proper endpoint

            this.$emit('add', this.newTenant);
        },
        resetForm(){
            this.newTenant = {};
            this.availableTenant = {
                value: '-1',
                text: this.i18nT(`Choisir`),
            };
        },
    },
    watch: {
        tenant: {
            handler: function (newVal) {


                if(newVal){

                    this.newTenant = {...newVal};
                    this.phone= this.newContact.TenantMobilePhone;
                    this.phoneReady = 'yes';
                }
            },
            deep: true
        },
    }
}

</script>