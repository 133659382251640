<template>
    <div>
        <ellipsis-dropdown
            componentName="leases"
            :showDeleteBtn="curTenancyId !== undefined"
        />

    <validation-observer ref="simpleRules">
        <b-tabs
            v-model="tabIndex"
            vertical
            content-class="cols-12 col-md-9 mt-1 mt-md-0"
            nav-wrapper-class="col-12 col-md-3"
            nav-class="nav-left"
            no-nav-style
        >

<!--            GENERAL INFO-->

            <b-tab
                @click="changeTab('general-info')"
            >
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="circle-info"/>
                        </div>
                        <div>
                            <h5 class="m-0">
                                <strong>{{ i18nT(`Informations générales`)}}</strong>
                            </h5>
                            <p class="m-0 text-secondary" v-if="false">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Informations générales`)}}</h2>
                    </b-card-header>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Bien loué`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="m-1">
                        <b-col
                            cols="12"
                            class="my-1"
                        >
                            <b-row class="mb-1" v-if="curTenancyId">
                                <b-col cols="12" md="6">
                                        <b-form-group :label="i18nT(`Etat de la location`)">
                                            <div class="d-flex align-items-center">
                                                <b-form-checkbox
                                                    switch
                                                    v-model="tenancyActive"
                                                />
                                                <div class="d-flex">
                                            <span class="mr-50">
                                                {{ i18nT(`La location est`) }}
                                            </span>
                                                    <b-badge
                                                        :variant="tenancyData.LeaseActive == 1 ? 'light-success' : 'light-secondary'"
                                                        class="badge-pill"
                                                    >
                                                        {{
                                                            tenancyData.LeaseActive == 1 ? i18nT(`Active`) : i18nT(`Inactive`)
                                                        }}
                                                    </b-badge>
                                                </div>
                                            </div>
                                        </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col
                                    cols="12"
                                    md="6"
                                >
                                    <b-form-group :label="i18nT(`Bien`)" class="required">
                                        <validation-provider
                                            :name="i18nT(`Bien`)"
                                            #default="{ errors }"
                                            rules="required"
                                        >
                                            <v-select
                                                :id="i18nT(`Bien`)"
                                                v-model="tenancyData.PropertyID"
                                                :options="properties"
                                                label="text"
                                                :clearable="false"
                                                :reduce="e=>+(e.value)"
                                                :class="{ 'border-invalid': errors.length > 0 }"
                                                :placeholder="i18nT(`Choisir`)"
                                            />
                                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-1 ml-1 mb-1">{{ i18nT(`Détails de la location`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1">
                        <b-col cols="12">
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Type`)" class="required">
                                        <validation-provider
                                            :name="i18nT(`Type`)"
                                            #default="{errors}"
                                            rules="required"
                                        >
                                            <v-select
                                                :id="i18nT(`Type`)"
                                                v-model="tenancyType"
                                                :options="tenancyTypes"
                                                label="text"
                                                :clearable="false"
                                                :reduce="e=>+(e.value)"
                                                class="mb-1 required"
                                                :class="{ 'border-invalid': errors.length > 0 }"
                                                :placeholder="i18nT(`Choisir`)"
                                                @input="getTenancyType(tenancyType)"
                                            />
                                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col
                                    cols="12" md="6">
                                    <b-form-group
                                        :label="i18nT(`Identifiant`)"
                                    >
                                            <b-form-input
                                                :id="i18nT(`Identifiant`)"
                                                v-model="tenancyData.LeaseTitle"
                                            />
                                        <p class="pt-50">{{i18nT(`Saisir un identifiant, référence ou numéro unique.`)}}</p>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="curTenancyType.LeaseTypeReason==1">
                                <b-col
                                    cols="12" md="6">
                                    <b-form-group
                                        :label="i18nT(`Motif`)"
                                    >
                                            <b-form-input
                                                :id="i18nT(`Motif`)"
                                                v-model="tenancyData.LeaseReason"
                                            />
                                        <p class="pt-50">{{i18nT(`Saisir le motif justifiant ce type de location.`)}}</p>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="curTenancyType.LeaseTypeUtilisation != '0'" class="my-1">
                                <b-col cols="12"  md="6">
                                    <b-form-group :label="i18nT(`Utilisation`)"
                                                  v-if="curTenancyType.LeaseTypeUtilisation !='0'"
                                    >
                                        <b-form-radio-group
                                            stacked
                                            v-model="tenancyUsage"
                                            v-if="curTenancyType.LeaseTypeUtilisation =='1'"
                                        >
                                            <b-form-radio
                                                value="0"
                                                class="pb-1 mt-50"
                                            >
                                                {{i18nT(`Résidence principale du locataire`)}}
                                            </b-form-radio>
                                            <b-form-radio
                                                value="1"
                                                class="pb-1"
                                            >
                                                {{i18nT(`Résidence secondaire du locataire`)}}
                                            </b-form-radio>
                                            <b-form-radio
                                                value="2"
                                            >
                                                {{i18nT(`Le locataire est autorisé à exercer son activité professionnelle, à l'exclusion, cependant, de toute activité commerciale, artisanale ou industrielle`)}}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                    <b-form-input class="mb-1 pt-0" v-if="curTenancyType.LeaseTypeUtilisation == '2' || tenancyUsage=='2'" v-model="tenancyData.LeaseUsageActivity" :placeholder="i18nT(`Activité exercée dans les lieux loués`)" />
                                </b-col>
                            </b-row>
<!--                            <b-row v-if="curTenancyType.LeaseUsage==3" class="mb-1">-->
<!--                                <b-col cols="12" md="6" >-->
<!--                                    <b-form-input v-model="tenancyData.LeaseUsageActivity" :placeholder="i18nT(`Activité exercée dans les lieux loués`)" />-->
<!--                                </b-col>-->
<!--                            </b-row>-->
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Début du bail`)" class="required">
                                        <validation-provider
                                            :name="i18nT(`Début du bail`)"
                                            #default="{ errors }"
                                            rules="required|date_format"
                                            >
                                            <date-picker
                                                :id="i18nT(`Début du bail`)"
                                                v-model="startDate"
                                                :manual-input="true"
                                                reset-button
                                                :state="errors.length > 0 ? false:null"
                                            />
                                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Fin du bail`)">
                                            <date-picker
                                                v-model="endDate"
                                                :manual-input="true"
                                                reset-button
                                            />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Durée du bail`)">
                                        <b-form-input disabled v-model="tenancyDuration" />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="my-1" v-if="curTenancyType.LeaseTypeAutoRenew">
                                <b-col cols="12" md="12">
                                    <b-form-group :label="i18nT(`Renouvellement`)">
                                        <b-form-checkbox
                                            v-model="tenancyDurationType"
                                            switch
                                            size="md"
                                            class="mb-50"
                                        />
                                        <small>{{i18nT(`Si vous cochez la case Renouveler par tacite, le site continuera de générer les loyers après la date de fin indiquée.`)}}</small>

                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1" >
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Paiement`)" class="required">
                                        <validation-provider
                                            :name="i18nT(`Paiement`)"
                                            #default="{errors}"
                                            rules="required"
                                        >
                                            <v-select
                                                :id="i18nT(`Paiement`)"
                                                v-model="tenancyBillingPeriod"
                                                :options="tenancyBillingOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="e=>e.value"
                                                class="mb-1 required"
                                                :class="{ 'border-invalid': errors.length > 0 }"
                                                :placeholder="i18nT(`Choisir`)"
                                                @input="getTenancyBillingEndDate(startDate,tenancyBillingPeriod)"
                                            />
                                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                        </validation-provider>
                                        <b-form-radio-group class="d-flex" v-model="tenancyData.LeasePaymentArrears">
                                            <b-form-radio
                                                value="0"
                                                class="text-nowrap"
                                            >{{i18nT(`Paiement à échoir`)}}</b-form-radio>
                                            <b-form-radio
                                                value="1"
                                                class="text-nowrap"
                                            >{{i18nT(`Paiement à terme échu`)}}</b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Moyen de paiement`)" class="mb-0">
                                            <v-select
                                                v-model="tenancyData.LeasePaymentMethodID"
                                                :options="tenancyPaymentMethods"
                                                label="text"
                                                :clearable="false"
                                                :reduce="e=>+(e.value)"
                                                class="mb-1"
                                                :placeholder="i18nT(`Choisir`)"
                                            />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <small>{{i18nT(`En choisissant le mode Prélèvement automatique, le système générera les loyers comme payés pour vous faire gagner du temps.`)}}</small>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                        <b-form-group :label="i18nT(`Date de paiement`)" class="mb-0">
                                            <v-select
                                                v-model="tenancyData.LeaseDayToBePaid"
                                                :options="daysArr"
                                                :clearable="false"
                                                class="mb-50"
                                                :placeholder="i18nT(`Choisir`)"
                                            />
                                            <small>{{i18nT(`Date de paiement du loyer prévue dans le contrat.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-3 ml-1 mb-1">{{ i18nT(`Loyer`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 mb-2">
                        <b-col cols="12">
                            <b-row class="mt-2">
                                <b-col cols="12" md="6" >
                                    <b-form-group :label="i18nT(`Loyer hors charges`)" class="required">
                                            <validation-provider
                                                :name="i18nT(`Loyer hors charges`)"
                                                #default="{ errors }"
                                                rules="required"
                                            >
                                                <b-input-group
                                                    class="border-right-none"

                                                >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" :class="{'prepend-invalid-border': errors.length > 0}">EUR</span>
                                                    </div>
                                                    <b-form-input
                                                        :id="i18nT(`Loyer hors charges`)"
                                                        v-model="tenancyData.LeaseRentHC"
                                                        type="number"
                                                        @change="calculateMonthlyAmount"
                                                        :state="errors.length > 0 ? false:null"
                                                    />
                                                </b-input-group>
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6">
                                    <b-form-group>
                                        <b-input-group
                                            prepend="%"
                                            class="border-right-none w-50 mt-2 pt-50"

                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseRentHCTaxPercent"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="12" md="6" >
                                    <b-form-group :label="i18nT(`Charges`)">
                                                <b-input-group
                                                    prepend="EUR"
                                                    class="border-right-none"
                                                >
                                                    <b-form-input
                                                        v-model="tenancyData.LeaseMaintenance"
                                                        type="number"
                                                        @change="calculateMonthlyAmount"
                                                    />
                                                </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6">
                                    <b-form-group>
                                        <b-input-group
                                            prepend="%"
                                            class="border-right-none w-50 mt-2 pt-50"

                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseMaintenanceTaxPercent"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-radio-group class="d-flex">
                                        <b-form-radio
                                            v-model="tenancyData.LeaseMaintenanceType"
                                            value="1"
                                            class="text-nowrap"
                                        >{{i18nT(`Provision pour charges`)}}</b-form-radio>
                                        <b-form-radio
                                            v-model="tenancyData.LeaseMaintenanceType"
                                            value="2"
                                            class="text-nowrap"
                                        >{{i18nT(`Forfait de charges`)}}</b-form-radio>
                                    </b-form-radio-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1" v-if="tenancyData.LeaseMaintenanceType == '2'">
                                <b-col cols="12">
                                    <b-form-textarea
                                        v-model="tenancyData.LeaseMaintenanceDescription"
                                        :placeholder="i18nT(`Description des charges incluses dans le forfait ...`)"
                                        rows="3"
                                    />
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="12" md="6" >
                                    <b-form-group :label="i18nT(`Loyer charges comprises`)" class="required">
                                        <validation-provider
                                            :name="i18nT(`Loyer charges comprises`)"
                                            #default="{ errors }"
                                            rules="required"
                                        >
                                            <b-input-group
                                                class="border-right-none"

                                            >
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" :class="{'prepend-invalid-border': errors.length > 0}">EUR</span>
                                                </div>
                                                <b-form-input
                                                    :id="i18nT(`Loyer charges comprises`)"
                                                    v-model="monthlyAmount"
                                                    type="number"
                                                    :state="errors.length > 0 ? false:null"
                                                    @change="calculateRentFromMonthlyAmount"
                                                />
                                            </b-input-group>
                                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Autres paiements`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 mb-2">
                        <b-col cols="12">
                            <b-row>
                                <b-col>
                                    <b-row
                                           class="border-bottom my-1"
                                           v-for="(item,index) in paymentItems"
                                           :key="'item+' + index"
                                    >
                                        <b-col
                                            cols="12"
                                            md="3"
                                            class="mt-1"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none mb-50"
                                            >
                                                <b-form-input
                                                    v-model="item['LeasePaymentItemsAmount']"
                                                    :placeholder="i18nT(`Montat`)"
                                                    type="number"
                                                />
                                            </b-input-group>
                                        </b-col>
                                        <b-col
                                            cols="12"
                                            md="3"
                                            class="mt-1"
                                        >
                                            <b-input-group
                                                prepend="%"
                                                class="border-right-none mb-50"
                                            >
                                                <b-form-input
                                                    id="keyNumber"
                                                    type="number"
                                                    v-model="item['LeasePaymentItemsTaxPercent']"
                                                    :placeholder="i18nT(`TVA`)"
                                                />
                                            </b-input-group>
                                        </b-col>
                                        <b-col
                                            cols="12"
                                            md="3"
                                            class="mt-1"
                                        >
                                            <v-select
                                                v-model="item['LeasePaymentItemsType']"
                                                :options="paymentItemTypes"
                                                label="text"
                                                :clearable="false"
                                                :reduce="e=>e.value"
                                                :placeholder="i18nT(`Choisir`)"
                                            />
                                        </b-col>
                                        <b-col
                                            cols="12"
                                            class="mt-1"
                                            md="3"
                                        >
                                            <b-form-input
                                                id="comment"
                                                v-model="item['LeasePaymentItems_Description']"
                                                :placeholder="i18nT(`Commentaire`)"
                                            />
                                        </b-col>
                                        <b-col
                                            cols="12"
                                            class="text-right mt-1 mb-1"
                                        >
                                            <b-button
                                                variant="outline-danger"
                                                @click="removePaymentItem(index)"
                                            >
                                                {{i18nT(`Supprimer l'élément`)}}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <b-row
                                        class="pt-2"
                                    >
                                        <b-col
                                            cols="12"
                                            md="9"
                                        >
                                            <b-button
                                                @click="addPaymentItem"
                                                variant="outline-primary"
                                            >
                                                <rt-icon icon="circle-plus" variant="light"/>
                                                {{i18nT(`Ajouter un élément`)}}
                                            </b-button>
                                        </b-col>

                                    </b-row>
                                    <b-row class="mt-50">
                                        <b-col>
                                            <div>{{i18nT(`Autres paiements exceptionnels tels que le ménage, parking etc. Ces paiements figureront sur chaque quittance et s'ajoutent au Loyer.`)}}</div>
                                        </b-col>
                                    </b-row>

                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Première quittance`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 mb-2">
                        <b-col cols="12">
                            <b-row class="mt-2">
                                <b-col cols="12">
                                    <b-form-checkbox
                                        v-model="tenancyData.LeaseFirstBill"
                                        value="1"
                                    >
                                        {{i18nT(`Cocher la case en cas de premier loyer au prorata.`)}}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Date fin de période`)"
                                                  class="mb-50"
                                    >
                                        <date-picker
                                            v-model="firstBillEndDate"
                                            :manual-input="true"
                                            reset-button
                                        />
                                    </b-form-group>
                                <small>{{i18nT(`La date de fin de période pour la 1ère quittance`)}}</small>
                                </b-col>
                            </b-row>
                            <b-row class="my-2">
                                <b-col cols="12" md="6">
                                    <b-button variant="outline-info"
                                              @click="calculateFirstPayment"
                                              class="my-1"
                                    >
                                        <rt-icon icon="calculator-alt" variant="light mr-50" :size="16"/>
                                        {{i18nT(`Calculer les montants`)}}
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Loyer hors charges`)">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseFirstBillAmount"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Charges`)">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseFirstBillCharges"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Dépôt de garantie`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 mb-2">
                        <b-col cols="12">
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Dépôt de garantie`)" class="required">
                                        <validation-provider
                                            :name="i18nT(`Dépôt de garantie`)"
                                            #default="{ errors }"
                                            rules="required"
                                        >
                                            <b-input-group
                                                class="border-right-none"
                                            >
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" :class="{'prepend-invalid-border': errors.length > 0}">EUR</span>
                                                </div>
                                                <b-form-input
                                                    :id="i18nT(`Dépôt de garantie`)"
                                                    v-model="tenancyData.LeaseSecurityDeposit"
                                                    type="number"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                            </b-input-group>
                                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Allocations logement`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 mb-2">
                        <b-col cols="12">
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Paiement CAF/APL`)" class="mb-50">
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none"
                                            >
                                                <b-form-input
                                                    v-model="tenancyData.LeasePaymentCafAmount"
                                                    type="number"
                                                />
                                            </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                    <small>{{i18nT(`Renseigner ici les aides telles que la CAF, l'APL, qui vous sont versées automatiquement. Le paiement sera généré automatiquement chaque mois comme déjà perçu.`)}}</small>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Frais de retard`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1 mx-1">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Frais de retard`)">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none mb-50"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseLateFee"
                                                type="number"
                                            />
                                        </b-input-group>
                                        <small>{{ i18nT(`Information à titre indicatif`) }}</small>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="3">
                                    <b-form-group class="mt-2 pt-50">
                                        <v-select
                                            v-model="tenancyData.LeaseLateFeeType"
                                            :options="lateFeeTypes"
                                            label="text"
                                            :clearable="false"
                                            :reduce="e=>e.value"
                                        />
                                    </b-form-group>

                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Loyers prépayés ou solde locataire`)}}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 mb-2">
                        <b-col cols="12">
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Montant`)" class="mb-50">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeasePrepaidRent"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                    <small>{{i18nT(`Information reprise dans le solde locataire. Vous pouvez reporter le solde des comptes à un loyer ultérieur.`)}}</small>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Révision de loyer`)}}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2 mx-1">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Réviser le loyer selon`)">
                                        <b-form-radio-group
                                            v-model="tenancyRentRevisionType"
                                        >
                                            <b-form-radio
                                                value="index"
                                                class="pb-1 mt-50"
                                            >
                                                {{i18nT(`Un indice de référence des loyers`)}}
                                            </b-form-radio>
                                            <b-form-radio
                                                value="%"
                                                class="pb-1"
                                            >
                                                {{i18nT(`Un pourcentage convenu à la hausse`)}}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="tenancyRentRevisionType=='index'">
                                <b-col cols="12">
                                    <b-form-group :label="i18nT(`Indice de Référence`)">
                                        <b-row>
                                            <b-col cols="12" md="3" >
                                            <v-select
                                                v-model="tenancyData.LeaseIndexType"
                                                :options="indexTypes"
                                                label="text"
                                                :clearable="false"
                                                :reduce="e=>e.value"
                                                :placeholder="i18nT(`Choisir`)"
                                                @input="getIndexValues(tenancyData.LeaseIndexType)"
                                            />
                                                </b-col>
                                            <b-col cols="12" md="3" v-if="tenancyData.LeaseIndexType != 'other'">
                                            <v-select
                                                v-model="tenancyData.LeaseIndexValue"
                                                :options="indexValues"
                                                label="text"
                                                :clearable="false"
                                                :reduce="e=>e.value"
                                                :placeholder="i18nT(`Choisir`)"
                                            />
                                            </b-col>
                                            <b-col cols="12" md="3" v-if="tenancyData.LeaseIndexType == 'other'">
                                                <b-form-input
                                                    v-model="tenancyData.LeaseIndex"
                                                    type="number"
                                                    :placeholder="i18nT(`Valeur d'indice`)"
                                                />
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row
                                v-if="tenancyRentRevisionType=='index'"
                                class="mt-2">
                                <b-col cols="12" md="12">
                                    <b-form-group :label="i18nT(`Révision automatique`)">
                                        <b-form-checkbox
                                            v-model="tenancyAutomaticRevision"
                                            switch
                                            size="md"
                                            class="mb-50"
                                        />
                                        <small>{{i18nT(`Si vous activez cette option, le site révisera automatiquement, chaque année à la date anniversaire, le montant du loyer. Vous recevrez un email de notification.`)}}</small>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="tenancyRentRevisionType=='%'">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Pourcentage`)">
                                        <b-input-group
                                            prepend="%"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseRentRevisionPercent"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Période`)">
                                        <v-select
                                            v-model="tenancyData.LeaseRentRevisionYears"
                                            :options="tenancyRevisionYears"
                                            label="text"
                                            :clearable="false"
                                            :reduce="e=>e.value"
                                            :placeholder="i18nT(`Choisir`)"
                                            class="mb-50"
                                        />
                                        <small>{{i18nT(`Période de révision du loyer.`)}}</small>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Encadrement des loyers`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2 mx-1">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="12">
                                    <b-form-group>
                                        <div class="d-flex align-items-center">
                                            <p class="m-0">{{ i18nT(`Le loyer du logement objet du présent contrat est-il soumis au loyer de référence majoré fixé par arrêté préfectoral ?`) }}
                                                <span v-b-tooltip.hover="i18nT(`Dans certaines villes, les loyers sont encadrés. Vous pouvez désormais connaître le loyer maximum dans votre quartier.`)">
                                                <rt-icon icon="info-circle" variant="light ml-50 text-info"/>
                                                </span>
                                            </p>
                                        </div>
                                        <b-form-radio-group
                                            v-model="tenancyData.LeaseLimitedRent"
                                        >
                                            <b-form-radio
                                                value="1"
                                                class="pb-1 mt-50"
                                            >
                                                {{i18nT(`Oui`)}}
                                            </b-form-radio>
                                            <b-form-radio
                                                value="0"
                                                class="pb-1"
                                            >
                                                {{i18nT(`Non`)}}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" md="12">
                                    <b-form-group>
                                        <div class="d-flex align-items-center">
                                            <p class="m-0">
                                                {{ i18nT(`Le logement est-il dans une zone où l'évolution du loyer est plafonnée à l'IRL ?`) }}
                                                <span v-b-tooltip.hover="i18nT(`Les décrets du 10 juin 2015 et du décret du 29 juillet 2016 précisent ce zonage, en renvoyant aux agglomérations listées en annexe du décret n° 2013-392 du 10 mai 2013 relatif au champ d'application de la taxe annuelle sur les logements vacants (par exemple : Bordeaux, Grenoble, Lille, Lyon, Marseille, Montpellier, Nantes, Nice, Paris, Strasbourg, Toulon, Toulouse, etc.).`)">
                                                    <rt-icon icon="info-circle" variant="light ml-50 text-info" />
                                                </span>
                                            </p>
                                        </div>
                                        <b-form-radio-group
                                            v-model="tenancyData.LeaseLimitedZone"
                                        >
                                            <b-form-radio
                                                value="1"
                                                class="pb-1 mt-50"
                                            >
                                                {{i18nT(`Oui`)}}
                                            </b-form-radio>
                                            <b-form-radio
                                                value="0"
                                                class="pb-1"
                                            >
                                                {{i18nT(`Non`)}}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Loyer de référence €/m²`)" class="mb-50">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseRentHCTaxPercent"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                    <small>{{i18nT(`Montant mensuel du loyer de référence €/m².`)}}</small>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Loyer majoré €/m²`)" class="mb-50">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseRentReferenceMaj"
                                                type="number"

                                            />
                                        </b-input-group>
                                    </b-form-group>
                                    <small>{{i18nT(`Montant mensuel du loyer de référence majoré €/m².`)}}</small>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Complément de loyer`)" class="mb-50">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseRentSupplement"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="12">
                                    <b-form-textarea
                                    v-model="tenancyData.LeaseRentSupplementDescription"
                                    rows="3"
                                    :placeholder="i18nT(`Description des caractéristiques du logement justifiant le complément de loyer`) + '...'"
                                    class="mb-50"
                                    />
                                    <small>{{i18nT(`A remplir si votre loyer est supérieur au loyer de référence majoré. Le complement de loyer doit être justifié.`)}}</small>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="12" md="12">
                                    <b-form-group>
                                        <div class="d-flex align-items-center">
                                            <p class="m-0">{{ i18nT(`Le bail du précédent locataire est-il terminé depuis plus de 18 mois ?`) }}
                                            </p>
                                        </div>
                                        <b-form-radio-group
                                            v-model="tenancyData.LeaseLastLeasePeriod"
                                        >
                                            <b-form-radio
                                                value="1"
                                                class="pb-1 mt-50"
                                            >
                                                {{i18nT(`Oui`)}}
                                            </b-form-radio>
                                            <b-form-radio
                                                value="0"
                                                class="pb-1"
                                            >
                                                {{i18nT(`Non`)}}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="tenancyData.LeaseLastLeasePeriod==0">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Dernier loyer appliqué`)" class="mb-50">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseLastRent"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                    <small>{{i18nT(`Montant du dernier loyer acquitté par le précédent locataire.`)}}</small>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2" v-if="tenancyData.LeaseLastLeasePeriod == 0">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Date de versement`)">
                                        <date-picker
                                            v-model="tenancyData.LeaseLastRentPayDate"
                                            :manual-input="true"
                                            reset-button
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="tenancyData.LeaseLastLeasePeriod==0">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Dernière révision`)">
                                        <date-picker
                                            v-model="tenancyData.LeaseLastRentUpdate"
                                            :manual-input="true"
                                            reset-button
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row :class="{'mt-1' : tenancyData.LeaseLastLeasePeriod==0 }">
                                <b-col cols="12" md="12">
                                    <b-form-group>
                                        <div class="d-flex align-items-center">
                                            <p class="m-0">{{ i18nT(`Le loyer fait-il l'objet d'une réévaluation ?`) }}
                                            </p>
                                        </div>
                                        <b-form-radio-group
                                            v-model="tenancyData.LeaseRentReevaluation"
                                        >
                                            <b-form-radio
                                                value="1"
                                                class="pb-1 mt-50"
                                            >
                                                {{i18nT(`Oui`)}}
                                            </b-form-radio>
                                            <b-form-radio
                                                value="0"
                                                class="pb-1"
                                            >
                                                {{i18nT(`Non`)}}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="tenancyData.LeaseRentReevaluation == '1'">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Montant de la hausse de loyer mensuelle`)" class="mb-50">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseRentReevaluationAmount"
                                                type="number"
                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2" v-if="tenancyData.LeaseRentReevaluation == '1'">
                                <b-col cols="12" md="12">
                                    <b-form-group>
                                        <div class="d-flex align-items-center">
                                            <p class="m-0">{{ i18nT(`Modalité d'application annuelle de la hausse`) }}
                                            </p>
                                        </div>
                                        <b-form-radio-group
                                            v-model="tenancyData.LeaseRentReevaluationType"
                                        >
                                            <b-form-radio
                                                value="1"
                                                class="pb-1 mt-50"
                                            >
                                                {{i18nT(`Par tiers`)}}
                                            </b-form-radio>
                                            <b-form-radio
                                                value="2"
                                                class="pb-1"
                                            >
                                                {{i18nT(`Par sixième`)}}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12" class="required">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Locataires`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mr-1 ml-1 mb-2">
                        <b-col
                            cols="12"
                            class="pt-1"
                        >
                            <validation-provider
                                :name="i18nT(`Locataires`)"
                                #default="{ errors }"
                                rules="tenants"
                            >
                                <div v-for="(tenant, index) in tenants"
                                     :key="'tenant-'+index"
                                     class="py-1"
                                     v-model="validateTenant"
                                >
                                    <tenant-card :tenant="tenant" :editable="true" @edit="onEditTenant"/>
                                </div>
                                <div class="pt-1 mb-1">
                                    <b-button
                                        :id="i18nT(`Locataires`)"
                                        variant="outline-primary"
                                        @click="openModal('tenant')"
                                    >
                                        <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                        {{i18nT(`Ajouter un locatiare`)}}
                                    </b-button>
                                </div>
                                <div class="d-flex flex-column">
                                    <small class="text-danger mb-50">{{ i18nT(errors[0]) }}</small>
                                    <small :class="{'text-danger': errors.length>0}">{{ i18nT(`Cliquez sur le bouton pour ajouter un ou plusieurs locataires ou co-locataires.`) }}</small>
                                </div>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="border-top pt-1">
                        <b-col cols="6" class="text-muted">
                            <div  class="m-1 pl-1">
                                <div v-if="tenancyData.LeaseCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Créé le`) }} {{ tenancyData.LeaseCreateTime}}
                                    </small>
                                </div>
                                <div v-if="tenancyData.LeaseLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Modifié le `) }} {{ tenancyData.LeaseLastEditDate }}
                                    </small>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="text-right mt-1 mb-0 mx-1">
                                <b-button
                                    variant="outline-secondary"
                                    class="mb-1 mr-1"
                                    :to="{ name: 'properties'}"
                                >
                                    {{i18nT(`Annuler`)}}
                                </b-button>
                                <b-button
                                    variant="primary"
                                    class="mb-1"
                                    @click.prevent="validationForm"
                                >
                                    {{i18nT(`Sauvegarder`)}}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>

<!--            ADDITIONAL INFO-->

            <b-tab
                @click="changeTab('additional-info')"
            >
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="sitemap"/>
                        </div>
                        <div>
                            <h5 class="m-0">
                                <strong>{{ i18nT(`Informations complémentaires`)}}</strong>
                            </h5>
                            <p class="m-0 text-secondary" v-if="false">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Informations complémentaires`)}}</h2>
                    </b-card-header>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Travaux propriétaire`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Montat`)">
                                        <b-input-group
                                            prepend="EUR"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseLandlordWorksAmount"
                                                type="number"
                                                class=""

                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Description`)" class="mb-50">
                                        <b-form-textarea
                                            v-model="tenancyData.LeaseLandlordWorks"
                                            rows="3"
                                            :placeholder="i18nT(`Description des travaux`) + '...'"
                                        />
                                    </b-form-group>
                                    <small>{{i18nT(`Travaux d'amélioration effectués par le propriétaire. Ces informations sont reprises sur le bail.`)}}</small>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-1 ml-1 mb-1">{{ i18nT(`Travaux locataire`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Montat`)">
                                        <b-input-group
                                            prepend="EUR"
                                        >
                                            <b-form-input
                                                v-model="tenancyData.LeaseTenantWorksAmount"
                                                type="number"
                                                class=""

                                            />
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Description`)" class="mb-50">
                                        <b-form-textarea
                                            v-model="tenancyData.LeaseTenantWorks"
                                            rows="3"
                                            :placeholder="i18nT(`Description des travaux`) + '...'"
                                        />
                                    </b-form-group>
                                    <small>{{i18nT(`Travaux effectués par le locataire. Ces informations sont reprises sur le bail.`)}}</small>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-1 ml-1 mb-1">{{ i18nT(`Conditions particulières`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12">
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Conditions particulières`)" class="mb-50">
                                        <b-form-textarea
                                            v-model="tenancyData.LeaseSpecialTerms"
                                            rows="3"
                                            :placeholder="i18nT(`Conditions particulières`) + '...'"
                                        />
                                    </b-form-group>
                                    <small>{{i18nT(`Ces informations sont reprises sur le bail.`)}}</small>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Clauses particulières`)" class="mb-50">
                                        <b-form-textarea
                                            v-model="tenancyData.LeaseSpecialClause"
                                            rows="3"
                                            :placeholder="i18nT(`Clauses particulières`) + '...'"
                                        />
                                    </b-form-group>
                                    <small>{{i18nT(`Ces informations sont reprises sur le bail.`)}}</small>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-1 ml-1 mb-1">{{ i18nT(`Commentaires sur la location`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12">
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Commentaires`)" class="mb-50">
                                        <b-form-textarea
                                            v-model="tenancyData.LeaseComments"
                                            rows="3"
                                            :placeholder="i18nT(`Notes sur l'entrée, sortie du locataire, relevés des compteurs`) + '...'"
                                        />
                                    </b-form-group>
                                    <small>{{i18nT(`Notes privées sur l'entrée et la sortie du locataire.`)}}</small>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-top pt-1">
                        <b-col cols="6" class="text-muted">
                            <div  class="m-1 pl-1">
                                <div v-if="tenancyData.LeaseCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Créé le`) }} {{ tenancyData.LeaseCreateTime}}
                                    </small>
                                </div>
                                <div v-if="tenancyData.LeaseLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Modifié le `) }} {{ tenancyData.LeaseLastEditDate }}
                                    </small>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="text-right mt-1 mb-0 mx-1">
                                <b-button
                                    variant="outline-secondary"
                                    class="mb-1 mr-1"
                                    :to="{ name: 'properties'}"
                                >
                                    {{i18nT(`Annuler`)}}
                                </b-button>
                                <b-button
                                    variant="primary"
                                    class="mb-1"
                                    @click.prevent="validationForm"
                                >
                                    {{i18nT(`Sauvegarder`)}}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>

<!--            RECEIPTS-->

            <b-tab @click="changeTab('receipts')">
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="file-invoice"/>
                        </div>
                        <div>
                            <h5 class="m-0">
                                <strong>{{ i18nT(`Quittances`)}}</strong>
                            </h5>
                            <p class="m-0 text-secondary" v-if="false">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Quittances`)}}</h2>
                    </b-card-header>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Adresse de quittancement`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group>
                                            <b-form-checkbox
                                                v-model="tenancyData.LeaseIsOtherQuittanceAddress"
                                                value="1"
                                            >
                                                {{i18nT(`Cocher si l'adresse de quittancement est autre que l'adresse du bien loué`)}}
                                            </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Adresse`)" class="mb-50">
                                        <b-form-textarea
                                            v-model="tenancyData.LeaseOtherQuittanceAddress"
                                            rows="3"
                                        />
                                    </b-form-group>
                                    <small>{{i18nT(`Saisir si l'adresse de quittancement est différente de l'adresse du bien loué.`)}}</small>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Quittance`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Date de quittancement / Périodicité`)" class="mb-0">
                                        <v-select
                                            v-model="tenancyData.LeasePaymentDay"
                                            :options="paymentDaysArr"
                                            :clearable="false"
                                            :placeholder="i18nT(`Choisir`)"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                    <p class="mt-50 font-small-3">{{i18nT(`Cette date définit la période de la quittance de loyer. Si vous choisissez le 15 du mois, les quittances seront datées du 15 au 14 du mois suivant.`)}}</p>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Génération du loyer`)" class="mb-0">
                                        <v-select
                                            v-model="tenancyData.LeasePaymentCreateDay"
                                            :options="paymentCreateDayArr"
                                            :clearable="false"
                                            class="mb-50"
                                            :placeholder="i18nT(`Choisir`)"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                    <p class="mb-50 mt-1 font-small-3">{{i18nT(`Si vous choisissez le J-5, le loyer et l'avis d'échéance sera généré 5 jours avant la date de quittancement. Ex. Le loyer du 1 au 30 Avril sera généré le 26 Mars.`)}}</p>
                                    <p class="font-small-3">{{i18nT(`Pour chaque contrat de location, les loyers sont générés automatiquement chaque mois dans la rubrique Finances.`)}}</p>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Titre du document`)" class="mb-0">
                                        <v-select
                                            v-model="tenancyData.LeaseMentionText"
                                            :options="tenancyMentionTexts"
                                            :clearable="false"
                                            :reduce="e=>e.value"
                                            label="text"
                                            :placeholder="i18nT(`Choisir`)"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="my-2">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Numérotation`)">
                                        <b-form-checkbox
                                            v-model="tenancyNumbering"
                                            switch
                                            size="md"
                                            class="mb-50"
                                        />
                                        <small>{{i18nT(`Activer ou désactiver la numérotation automatique du document.`)}}</small>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="tenancyNumbering">
                                <b-col cols="12" md="6">
                                    <b-form-input
                                        v-model="tenancyData.LeaseNumberingPrefix"
                                    />
                                    <p class="text-nowrap mt-50 font-small-3">{{i18nT(`Saisir un préfixe (ex.ABC), un compteur automatique sera rajouté à la suite de ce préfixe.`)}}</p>
                                </b-col>
                            </b-row>
                            <b-row v-if="tenancyNumbering">
                                <b-col cols="12" md="6">
                                    <b-form-radio-group
                                        v-model="tenancyUseGlobalNumbering"
                                    >
                                        <b-form-radio
                                            value="1"
                                            class="pb-1"
                                        >
                                            {{i18nT(`Numérotation globale pour le propriétaire`)}}
                                        </b-form-radio>
                                        <b-form-radio
                                            value="0"
                                            class="pb-1"
                                        >
                                            {{i18nT(`Numérotation distincte pour la location`)}}
                                        </b-form-radio>

                                    </b-form-radio-group>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Avis en deuxième page`)">
                                        <b-form-checkbox
                                            v-model="tenancyAvis"
                                            switch
                                            size="md"
                                            class="mb-50"
                                        />
                                        <small>{{i18nT(`Générer l'avis d'échéance pour le mois suivant en deuxième page de la quittance du mois en cours.`)}}</small>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Texte pour la quittance`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12">
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Texte`)" class="mb-50">
                                        <b-form-textarea
                                            v-model="tenancyData.LeasePaymentReceivedComments"
                                            rows="3"
                                            :placeholder="i18nT(`Ex. Paiement sous huitaine.`)"
                                        />
                                    </b-form-group>
                                    <small>{{i18nT(`Texte à afficher automatiquement en bas de la Quittance. Ex. Paiement sous huitaine.`)}}</small>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Texte pour l'avis d'échéance`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12">
                            <b-row class="my-1">
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Texte`)" class="mb-50">
                                        <b-form-textarea
                                            v-model="tenancyData.LeasePaymentAvisComments"
                                            rows="3"
                                            :placeholder="i18nT(`Ex. Paiement sous huitaine`) + '...'"
                                        />
                                    </b-form-group>
                                    <small>{{i18nT(`Texte à afficher automatiquement en bas de l'Avis d'échéance. Ex. Paiement sous huitaine.`)}}</small>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-top pt-1">
                        <b-col cols="6" class="text-muted">
                            <div  class="m-1 pl-1">
                                <div v-if="tenancyData.LeaseCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Créé le`) }} {{ tenancyData.LeaseCreateTime}}
                                    </small>
                                </div>
                                <div v-if="tenancyData.LeaseLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Modifié le `) }} {{ tenancyData.LeaseLastEditDate }}
                                    </small>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="text-right mt-1 mb-0 mx-1">
                                <b-button
                                    variant="outline-secondary"
                                    class="mb-1 mr-1"
                                    :to="{ name: 'properties'}"
                                >
                                    {{i18nT(`Annuler`)}}
                                </b-button>
                                <b-button
                                    variant="primary"
                                    class="mb-1"
                                    @click.prevent="validationForm"
                                >
                                    {{i18nT(`Sauvegarder`)}}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>

<!--                OTHER SETTINGS-->

            <b-tab @click="changeTab(`additional-settings`)">
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="list-check"/>
                        </div>
                        <div>
                            <h5 class="m-0">
                                <strong>{{ i18nT(`Autres réglages`)}}</strong>
                            </h5>
                            <p class="m-0 text-secondary" v-if="false">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Autres réglages`)}}</h2>
                    </b-card-header>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Report de solde`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-radio-group
                                        v-model="tenancySoldereport"
                                    >
                                        <b-form-radio
                                            value="0"
                                            class="pb-1 mt-50"
                                        >
                                            {{i18nT(`Manuel`)}}
                                        </b-form-radio>
                                        <b-form-radio
                                            value="1"
                                            class="pb-1"
                                        >
                                            {{i18nT(`Automatique`)}}
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </b-col>
                                <b-col cols="12">
                                    <p class="font-small-3 mt-0">{{i18nT(`En cas de report Automatique, à chaque nouveau paiement de loyer généré dans la rubrique Finances le système modifiera l’échéance. Le montant sera augmenté/diminué par rapport à la somme reportée.`)}}</p>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Notifications`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2">
                        <b-col cols="12" md="12">
                            <b-form-checkbox
                                v-model="notifyLandlord"
                                class="my-50"
                            >
                                {{i18nT(`Recevoir une notification dès que la Quittance / Avis est disponible en téléchargement`)}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols="12" md="12">
                            <b-form-checkbox
                                v-model="notifyTenant"
                                class="my-50"
                            >
                                {{i18nT(`Notifier le(s) locataire(s) dès que la Quittance / Avis est disponible en téléchargement`)}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols="12" md="12">
                            <b-form-checkbox
                                v-model="anniversaryNotifyLandlord"
                                class="my-50"
                            >
                                {{i18nT(`Envoyer un email de rappel 6 et 3 mois avant l’échéance du contrat`)}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols="12" md="12">
                            <b-form-checkbox
                                v-model="anniversaryNotifyTenant"
                                class="my-50"
                            >
                                {{i18nT(`Notifier le(s) locataire(s) 3 mois avant l’échéance du contrat`)}}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom" v-if="!curTenancyId">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Historique des loyers`) }}</h4>
                        </b-col>
                    </b-row>
<!--                    TODO: ASK WHAT VALUE TO SEND FOR GENERATE TENANCY PAYMENTS-->
                    <b-row class="mx-1 my-2" v-if="!curTenancyId">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Générer un historique`)" class="mb-0">
                                        <b-form-checkbox
                                            v-model="generateTenancyPayments"
                                            switch
                                            size="md"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                    <p class="mt-50 font-small-3">{{i18nT(`Régénérer les loyers pour cette location depuis le début. Valable uniquement pour les locations déjà en cours avec une date de début antérieure.`)}}</p>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom" v-if="!curTenancyId">
                        <b-col cols="12"
                        >
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Activation de la location`) }}</h4>
                        </b-col>
                    </b-row>
                    <b-row class="mx-1 my-2" v-if="!curTenancyId">
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="i18nT(`Activer la location`)" class="mb-0">
                                        <b-form-checkbox
                                            v-model="tenancyActive"
                                            switch
                                            size="md"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="border-top pt-1">
                        <b-col cols="6" class="text-muted">
                            <div  class="m-1 pl-1">
                                <div v-if="tenancyData.LeaseCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Créé le`) }} {{ tenancyData.LeaseCreateTime}}
                                    </small>
                                </div>
                                <div v-if="tenancyData.LeaseLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Modifié le `) }} {{ tenancyData.LeaseLastEditDate }}
                                    </small>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="text-right mt-1 mb-0 mx-1">
                                <b-button
                                    variant="outline-secondary"
                                    class="mb-1 mr-1"
                                    :to="{ name: 'properties'}"
                                >
                                    {{i18nT(`Annuler`)}}
                                </b-button>
                                <b-button
                                    variant="primary"
                                    class="mb-1"
                                    @click.prevent="validationForm"
                                >
                                    {{i18nT(`Sauvegarder`)}}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>

<!--            GARANTS-->

            <b-tab
                @click="changeTab('garants')"
            >
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="user"/>
                        </div>
                        <div>
                            <h5 class="m-0">
                                <strong>{{ i18nT(`Garants`)}}</strong>
                            </h5>
                            <p class="m-0 text-secondary" v-if="false">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Garants`)}}</h2>
                    </b-card-header>
                    <b-row class=" mr-1 ml-1 mb-2 pb-2">
                        <b-col cols="12"
                               md="12"
                               class="pt-1"
                        >
                            <div
                                v-for="(garant,index) in garants"
                                :key="`guarantor` + index"
                                class="pt-1"
                            >
                                <contact-card :contact="garant" :editable="true"  @edit="onEditContact(guarantor,'garant', index)" />
                            </div>
                            <div class="mb-1 pt-1">
                                <b-button
                                    variant="outline-primary"
                                    @click="openModal('garant')"
                                >
                                    <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                    {{i18nT(`Ajouter un garant`)}}
                                </b-button>
                            </div>
                            <small>{{i18nT(`Vous pouvez ajouter plusieurs garants si besoin. Ce contact sera sauvegardé dans la rubrique carnet.`)}}</small>
                        </b-col>
                    </b-row>
                    <b-row class="border-top pt-1">
                        <b-col cols="6" class="text-muted">
                            <div  class="m-1 pl-1">
                                <div v-if="tenancyData.LeaseCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Créé le`) }} {{ tenancyData.LeaseCreateTime}}
                                    </small>
                                </div>
                                <div v-if="tenancyData.LeaseLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Modifié le `) }} {{ tenancyData.LeaseLastEditDate }}
                                    </small>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="text-right mt-1 mb-0 mx-1">
                                <b-button
                                    variant="outline-secondary"
                                    class="mb-1 mr-1"
                                    :to="{ name: 'properties'}"
                                >
                                    {{i18nT(`Annuler`)}}
                                </b-button>
                                <b-button
                                    variant="primary"
                                    class="mb-1"
                                    @click.prevent="validationForm"
                                >
                                    {{i18nT(`Sauvegarder`)}}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>

<!--            INSURANCE-->

            <b-tab
                :title="i18nT(`Assurance`)"
                @click="changeTab('insurance')"
            >
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="file-certificate"/>
                        </div>
                        <div>
                            <h5 class="m-0">
                                <strong>{{ i18nT(`Assurance`)}}</strong>
                            </h5>
                            <p class="m-0 text-secondary" v-if="false">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Assurance`)}}</h2>
                    </b-card-header>
                    <b-row class=" mr-1 ml-1 mb-2 pb-2">
                        <b-col cols="12"
                               md="12"
                               class="pt-1"
                        >
                            <div
                                v-for="(item,index) in insurances"
                                :key="`document` + index"
                                class="pb-1 pt-1"
                            >
                                <document-card  :document="item" :editable="item['_can_edit']" @edit="onEditDocument" />
                            </div>
                            <div class="pt-1 mb-1">
                                <b-button
                                    variant="outline-primary"
                                    @click="openModal('insurance')"
                                >
                                    <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                    {{i18nT(`Ajouter une assurance`)}}
                                </b-button>
                            </div>
                            <small>{{i18nT(`Vous pouvez ajouter plusieurs contrats. Les fichiers seront sauvegardés dans la rubrique Documents.`)}}</small>
                        </b-col>
                    </b-row>
                    <b-row class="border-top pt-1">
                        <b-col cols="6" class="text-muted">
                            <div  class="m-1 pl-1">
                                <div v-if="tenancyData.LeaseCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Créé le`) }} {{ tenancyData.LeaseCreateTime}}
                                    </small>
                                </div>
                                <div v-if="tenancyData.LeaseLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Modifié le `) }} {{ tenancyData.LeaseLastEditDate }}
                                    </small>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="text-right mt-1 mb-0 mx-1">
                                <b-button
                                    variant="outline-secondary"
                                    class="mb-1 mr-1"
                                    :to="{ name: 'properties'}"
                                >
                                    {{i18nT(`Annuler`)}}
                                </b-button>
                                <b-button
                                    variant="primary"
                                    class="mb-1"
                                    @click.prevent="validationForm"
                                >
                                    {{i18nT(`Sauvegarder`)}}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>

<!--            DOCUMENTS-->
            <b-tab
                @click="changeTab('documents')"
            >
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="briefcase"/>
                        </div>
                        <div>
                            <h5 class="m-0">
                                <strong>{{ i18nT(`Documents`)}}</strong>
                            </h5>
                            <p class="m-0 text-secondary" v-if="false">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Documents`)}}</h2>
                    </b-card-header>
                    <b-row class=" mr-1 ml-1 mb-2 pb-2">
                        <b-col cols="12"
                               md="12"
                               class="pt-1"
                        >
                            <div
                                v-for="(item,index) in documents"
                                :key="`document` + index"
                                class="pb-1 pt-1"
                            >
                                <document-card  :document="item" :editable="item['_can_edit']" @edit="onEditDocument" />
                            </div>
                            <div class="pt-1 mb-1">
                                <b-button
                                    variant="outline-primary"
                                    @click="openModal"
                                >
                                    <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                    {{i18nT(`Ajouter un document`)}}
                                </b-button>
                            </div>
                            <small>{{i18nT(`Vous pouvez ajouter plusieurs documents. Ces documents seront sauvegardés dans la rubrique Documents.`)}}</small>
                        </b-col>
                    </b-row>
                    <b-row class="border-top pt-1">
                        <b-col cols="6" class="text-muted">
                            <div  class="m-1 pl-1">
                                <div v-if="tenancyData.LeaseCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Créé le`) }} {{ tenancyData.LeaseCreateTime}}
                                    </small>
                                </div>
                                <div v-if="tenancyData.LeaseLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                    <small>
                                        {{ i18nT(`Modifié le `) }} {{ tenancyData.LeaseLastEditDate }}
                                    </small>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="text-right mt-1 mb-0 mx-1">
                                <b-button
                                    variant="outline-secondary"
                                    class="mb-1 mr-1"
                                    :to="{ name: 'properties'}"
                                >
                                    {{i18nT(`Annuler`)}}
                                </b-button>
                                <b-button
                                    variant="primary"
                                    class="mb-1"
                                    @click.prevent="validationForm"
                                >
                                    {{i18nT(`Sauvegarder`)}}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>

        </b-tabs>
    </validation-observer>

<!--        MODALS-->

        <modal-add-tenant :tenant="curUser" @add="addTenant"/>
        <modal-add-contact :contact="curUser" :type="modalType" @add="addContact"/>
        <modal-add-document :documents="allDocuments" :type="documentModalType"/>

    </div>
</template>

<script>
import {
    BTabs,
    BCard,
    BTab,
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    BButton,
    VBTooltip,
    BBadge,
    BFormCheckbox,
    BImg,
    BDropdownItem,
    BDropdown,
} from "bootstrap-vue";
import EllipsisDropdown from "@core/components/EllipsisDropdown.vue";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import vSelect from 'vue-select';
import { required} from '@validations'
import DatePicker from "@core/components/DatePicker.vue";
import moment from "moment/moment";
import useAuth from "@/auth/useAuth";
import ModalAddTenant from "@/views/components/modalAddTenant/ModalAddTenant.vue";
import TenantCard from "@/views/components/tenantCard/TenantCard.vue";
import ContactCard from "@/views/components/contactCard/ContactCard.vue";
import ModalAddContact from "@/views/landlord/Tenants/ModalAddContact.vue";
import DocumentCard from "@/views/common/Documents/DocumentCard.vue";
import ModalAddDocument from "@/views/common/Documents/ModalAddDocument.vue";
import {dictToSelectArray} from "@core/utils/utils";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

extend('date_format', {
    validate(value) {
        return {
            required: true,
            valid: moment(value, this.dateFormat).isValid() && value.length === 10 && moment(value).isBetween('1900-01-01', '2999-12-31')
        };
    },
    message: `Invalid date.`,
})

extend('tenants',{
    validate(value){
        return{
            required: true,
            valid: value,
        };
    },
    computesRequired: true,
    message: `Veuillez saisir des locataires`,
})




export default{
    components: {
        ModalAddDocument,
        DocumentCard,
        ModalAddContact,
        ContactCard,
        TenantCard,
        ModalAddTenant,
        DatePicker,
        EllipsisDropdown,
        ValidationObserver,
        ValidationProvider,
        BTabs,
        BCard,
        BTab,
        BCol,
        BRow,
        BFormInput,
        BFormGroup,
        BButton,
        BBadge,
        BFormCheckbox,
        BImg,
        BDropdownItem,
        BDropdown,
        vSelect,
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data(){
        return{
            required,
            tabIndex: 0,
            tabNames: ['general-info', 'additional-info', 'receipts', 'additional-settings', 'garants', 'insurance', 'documents'],
            tenancyData:{},
            properties: [],
            tenancyTypes: [],
            tenancyType: {
                LeaseTypeUtilisation : '1',
                LeaseTypeReason: '1',
                LeaseTypeAutoRenew: 1,
                LeaseRentRevisionType: 'index',
            },
            tenancyUsage: '0',
            tenancyDuration: '',
            startDate: '',
            endDate: '',
            tenancyDurationType: true,
            tenancyNumbering: false,
            tenancyAvis: false,
            tenancyBillingOptions: [],
            tenancyPaymentMethods: [],
            daysArr: this.getDaysInMonthArr(31),
            monthlyAmount: '',
            paymentItems: [{}],
            paymentItemTypes: [
                {
                    value: 'rent',
                    text: this.i18nT(`Loyer`)
                },
                {
                value: 'charge',
                text: this.i18nT(`Charges`)
                },

            ],
            lateFeeTypes:[
                {value: 'percent', text: '%'},
                {value: 'fixed', text: '€'}
            ],
            indexTypes: [{
                value: 'IRL', text: 'IRL'
                },
                {
                    value: 'ILC', text: 'ILC'
                },
                {
                    value: 'ICC', text: 'ICC'
                },
                {
                    value: 'ILAT', text: 'ILAT'
                },
                {
                    value: 'INDICE SANTE', text: this.i18nT(`Indice de santé`),
                },
                {
                    value: 'other', text: this.i18nT(`Autre`),
                }],
            indexValues: [],
            indexesObj: null,
            tenancyAutomaticRevision: false,
            tenancyRevisionYears: [
                {value:'1', text: '1' + " " + this.i18nT(`an`)},
                {value:'3', text:'3' + " " + this.i18nT(`ans`)}
            ],
            tenants: [],
            validateTenant: {},
            curUser: null,
            curIndex: null,
            paymentDaysArr: Array.from({length:31} , (_, index) => index+1),
            paymentCreateDayArr: this.getPaymentCreateDayArr(),
            tenancyMentionTexts: [
                {value: 'Facture', text: this.i18nT(`Facture`)},
                {value: 'Quittance', text: this.i18nT(`Quittance`)}
            ],
            tenancySoldereport: '0',
            notifyLandlord: true,
            notifyTenant: true,
            anniversaryNotifyLandlord: true,
            anniversaryNotifyTenant: true,
            generateTenancyPayments: true,
            tenancyActive: true,
            garants: [],
            modalType: 'garant',
            insurances: [],
            documents: [],
            allDocuments: [],
            tenancyDocument: null,
            tenancyTypesFull: [],
            curTenancyType: {},
            tenancyBillingPeriod: 'monthly',
            firstBillEndDate: '',
            curTenancyId: router.currentRoute.params.id,
            tenancyUseGlobalNumbering: `1`,
            documentModalType: '',
            tenancyRentRevisionType: 'index',
        }
    },
    created(){

        if(this.$route.query.tenantId){
            const tenantId = this.$route.query.tenantId;
            this.$http.get(`landlord/tenants?id=${tenantId}`).then(({data})=>{
                this.tenants.push(data.data.tenant);
            })
        }

        this.$http.get(`landlord/leases/new`).then(({data})=>{
            this.properties = dictToSelectArray(data.data.properties);

            this.tenancyTypes = data.data['lease_types'].map(type=>{
                return {
               value: type.LeaseTypeID,
               text: type.LeaseTypeTitle
                }
           }).filter(type=>type.text != '');

            this.tenancyTypesFull= data.data['lease_types'];
            this.tenancyType = this.tenancyTypes.find(type=>type.value == '1');
            this.curTenancyType = this.tenancyTypesFull.find(type=>type.LeaseTypeID == '1');
            this.tenancyPaymentMethods = dictToSelectArray(data.data['payment_types']);
            this.tenancyBillingOptions = dictToSelectArray(data.data['billing_periods']);
            this.indexesObj = data.data['indexes'];

            if(!this.curTenancyId){
                if(this.$route.query.propertyId){
                    //TODO - check in validationForm if this remains an object or it's a string
                    this.tenancyData.PropertyID = this.properties.find(property=>property.value == this.$route.query.propertyId);
                }
                this.tenancyData.LeasePaymentArrears = '0';
                this.tenancyData.LeaseMaintenanceType = '1';
                this.tenancyData.LeaseRentRevisionType = 'index';
                this.tenancyData.LeaseLimitedRent = '0';
                this.tenancyData.LeaseLimitedZone = '0';
                this.tenancyData.LeaseLastLeasePeriod = '0';
                this.tenancyData.LeaseRentReevaluation = '1';
                this.tenancyData.LeaseRentReevaluationType = '1';
                this.tenancyData.LeaseTitle = 'Nouvelle location';
                this.tenancyData.LeaseIndexType = 'IRL';
                this.getIndexValues('IRL');
            }

        }).then(()=>{
            if(this.curTenancyId) {
                this.$http.get(`landlord/leases?id=${this.curTenancyId}&edit_date=1`)
                    .then(({data})=> {
                        this.tenancyData = {...data.data.lease};
                        this.tenancyActive = !!this.tenancyData.LeaseActive;
                        this.tenancyType = this.tenancyTypes.find(type=>type.value == this.tenancyData.LeaseType);
                        this.curTenancyType = this.tenancyTypesFull.find(type=>type.LeaseTypeID == this.tenancyType.value);
                        this.tenancyUsage = this.tenancyData.LeaseUsage;
                        this.startDate = this.tenancyData.LeaseStartDate ? this.tenancyData.LeaseStartDate : '';
                        this.endDate = this.tenancyData.LeaseEndDate ? this.tenancyData.LeaseEndDate : '';
                        this.tenancyDurationType = this.tenancyData.LeaseDurationType;
                        this.tenancyBillingPeriod = this.tenancyData.LeaseBillingPeriod;
                        this.monthlyAmount = this.tenancyData.LeaseMonthlyAmount ? this.tenancyData.LeaseMonthlyAmount : '';
                        this.tenancyData.LeasePaymentMethodID = this.tenancyData.LeasePaymentMethodID ? this.tenancyData.LeasePaymentMethodID : '';
                        this.firstBillEndDate = this.tenancyData.LeaseFirstBillEndDate ? this.tenancyData.LeaseFirstBillEndDate : '';
                        this.getIndexValues(this.tenancyData.LeaseIndexType ? this.tenancyData.LeaseIndexType : 'IRL');
                        this.tenancyAutomaticRevision = !!this.tenancyData.LeaseRentRevision;
                        this.tenancyData.LeasePaymentCreateDay = this.tenancyData.LeasePaymentCreateDay ? this.tenancyData.LeasePaymentDay : '';
                        this.tenancyNumbering = !!this.tenancyData.LeaseNumbering;
                        this.tenancyAvis = !!this.tenancyData.LeaseAvis;
                        this.tenancySoldereport = this.tenancyData.LeaseSoldeReport;
                        this.notifyLandlord = !!this.tenancyData.LeaseRentNotifyLandlord;
                        this.notifyTenant = !!this.tenancyData.LeaseRentNotifyTenant;
                        this.anniversaryNotifyLandlord = !!this.tenancyData.LeaseAnniversaryNotifyLandlord;
                        this.anniversaryNotifyTenant = !!this.tenancyData.LeaseAnniversaryNotifyTenant;
                        this.tenancyUseGlobalNumbering = this.tenancyData.LeaseUseGlobalNumbering;
                        this.tenancyRentRevisionType = this.tenancyData.LeaseRentRevisionType
                    })
            }
        })
    },
    computed: {
        dateFormat() {
            return useAuth.getDateFormat() != 'null'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        },
    },
    methods: {
        changeTab(tab){

            this.tabIndex = this.tabNames.indexOf(tab)
            let currentPath;

            if(!this.$route.params.id){
                currentPath='/landlord/leases/new'
            } else {
                currentPath=`/landlord/leases/${this.$route.params.id}/edit`
            }

            history.pushState({}, null,  currentPath+ "/" + encodeURIComponent(tab));
        },
        getTenancyDuration() {
            const start = moment(this.startDate);
            const end = moment(this.endDate);
            const duration = moment.duration(end.diff(start));

            const years = duration.years();
            const months = duration.months();
            const days = duration.days();

            let durationString = '';
            if (years > 0) {
                durationString += `${years} ${years > 1 ? this.i18nT(`ans`) : this.i18nT('an')} `;
            }
            if (months > 0) {
                durationString += `${months} ${this.i18nT(`mois`)} `;
            }
            if (days > 0) {
                durationString += `${days} ${days > 1 ? this.i18nT(`jours`) : this.i18nT(`jour`)}`;
            }
            this.tenancyDuration = durationString.trim();
        },
        getDaysInMonthArr(days){
            return Array.from({length:days} , (_, index) => index+1)
        },
        getPaymentCreateDayArr(){
            let negativeNumbersArr =  Array.from({length:31} , (_, index) => `J - ${index+1}`);
            let positiveNumbersArr =  Array.from({length:31} , (_, index) => `J + ${index+1}`)
            negativeNumbersArr = [...negativeNumbersArr, 'J - 60', 'J - 90'];
            positiveNumbersArr = [...positiveNumbersArr, 'J + 60', 'J + 90'];
            return [...negativeNumbersArr, ...positiveNumbersArr];

        },
        calculateMonthlyAmount(){
            const rent = parseFloat(this.tenancyData.LeaseRentHC) ? parseFloat(this.tenancyData.LeaseRentHC) : 0;
            const maintenance = parseFloat(this.tenancyData.LeaseMaintenance) ? parseFloat(this.tenancyData.LeaseMaintenance) : 0;

            let total = rent + maintenance;
            total = Math.round(total * 100) / 100

            this.monthlyAmount = total ? total : '0,00' ;
        },
        calculateRentFromMonthlyAmount(){
            const monthlyAmount = parseFloat(this.monthlyAmount) ? parseFloat(this.monthlyAmount) : 0;
            const maintenance = parseFloat(this.tenancyData.LeaseMaintenance) ? parseFloat(this.tenancyData.LeaseMaintenance) : 0;

            let rent = monthlyAmount - maintenance;
            rent = Math.round(rent * 100) / 100;

            this.tenancyData.LeaseRentHC = rent > 0 ? rent : '0,00';
        },
        calculateFirstPayment(){
            const startDate = moment(this.startDate);
            const firstBillEndDate = moment(this.firstBillEndDate);



            // if(!startDate.isValid() || !firstBillEndDate.isValid()){
            //     //TODO add error handling - show the user an error message
            //     return;
            // }

            // if(firstBillEndDate.isBefore(startDate)){
            //     //TODO add error handling - show the user an error message
            //     return;
            // }

            if(!this.tenancyData.LeaseRentHC && !this.tenancyData.LeaseMaintenance){
                this.tenancyData = {
                    ...this.tenancyData,
                    LeaseFirstBillAmount: '0.00',
                    LeaseFirstBillCharges: '0.00',
                }
                return;
            }

            const numDays = moment(startDate).endOf("month").format("DD");
            const partialDaysInMonth = numDays - startDate.format("DD") + 1;

            console.log('rent', this.tenancyData.LeaseRentHC);
            console.log('maintenance', this.tenancyData.LeaseMaintenance);
            console.log('numDays', numDays);
            console.log('partialDaysInMonth', partialDaysInMonth);

            let partialRent = this.tenancyData.LeaseRentHC ? parseInt(this.tenancyData.LeaseRentHC) * ((partialDaysInMonth / parseInt(numDays))) : 0;
            let partialMaintenance = this.tenancyData.LeaseMaintenance ? parseInt(this.tenancyData.LeaseMaintenance) * ((partialDaysInMonth / parseInt(numDays))) : 0;

            console.log('first partial rent', partialRent);
            console.log('first partial maintenance', partialMaintenance);

            const monthsDiff =  parseInt(moment(this.firstBillEndDate).diff(startDate, 'months', true));


            //recalculate partial rent and partial maintenance based on chosen billing period

            console.log('billing period', this.tenancyBillingPeriod);

            if(this.tenancyBillingPeriod == 'monthly'){
                if(monthsDiff){
                    partialRent += this.tenancyData.LeaseRentHC * parseInt(monthsDiff);
                    partialMaintenance += this.tenancyData.LeaseMaintenance * parseInt(monthsDiff);
                    console.log('new partialRent', partialRent);
                    console.log('new partialMaintenance', partialMaintenance);
                }
            }

            const getPartialRentMaintenance = (type, count) => {
                console.log('inside fn')
                if(type.includes('week')){
                    const daysDiff = parseInt(moment(firstBillEndDate).diff(startDate, 'days'));
                    console.log('daysDiff', daysDiff);

                    if(daysDiff==0){
                        partialRent = '0,00';
                        partialMaintenance = '0,00';
                    } else if(daysDiff>0){
                        partialRent = this.tenancyData.LeaseRentHC * daysDiff / count;
                        partialMaintenance = this.tenancyData.LeaseMaintenance * daysDiff / count;

                    }
                } else {
                    const futureMonth = moment(startDate).add(`${count}`, "M").format('YYYY-MM-DD');

                    const daysDiffFirst = moment(futureMonth).diff(startDate, 'days');


                    console.log('daysDiffFirst', daysDiffFirst);


                    const daysDiffSecond = moment(futureMonth).diff(firstBillEndDate, 'days');

                    console.log('daysDiffSecond', daysDiffSecond);

                    const newMonthsDiff = parseInt(monthsDiff / count);

                    console.log('new monthds diff', newMonthsDiff);


                    if(newMonthsDiff){

                        partialRent += (this.tenancyData.LeaseRentHC * newMonthsDiff);
                        partialMaintenance += (this.tenancyData.LeaseMaintenance * newMonthsDiff);

                    } else if(daysDiffFirst>0 && daysDiffSecond>0){

                        partialRent = this.tenancyData.LeaseRentHC * ((daysDiffFirst - daysDiffSecond)/daysDiffFirst);
                        partialMaintenance = this.tenancyData.LeaseMaintenance * ((daysDiffFirst - daysDiffSecond)/daysDiffFirst);
                    }

                }
            }

            switch (this.tenancyBillingPeriod) {
                case 'weekly':
                    getPartialRentMaintenance('week', 7);
                    break;
                case '2week':
                    getPartialRentMaintenance('2week', 14);
                    break;
                case '4week':
                    getPartialRentMaintenance('4week', 28);
                    break;
                case '2month':
                    getPartialRentMaintenance('2month', 2);
                    break;
                case '3month':
                    getPartialRentMaintenance('3month', 3);
                    break;
                case '4month':
                    getPartialRentMaintenance('4month', 4);
                    break;
                case '6month':
                    getPartialRentMaintenance('6month', 6);
                    break;
                case '1year':
                    getPartialRentMaintenance('1year', 12);
                    break;
            }

            //TODO needs debugging, returns NaN

            this.tenancyData = {
                ...this.tenancyData,
                LeaseFirstBillAmount: (Math.round(partialRent * 100) / 100).toFixed(2),
                LeaseFirstBillCharges: (Math.round(partialMaintenance * 100) / 100).toFixed(2)
            }

            console.log('partialRent', partialRent);
            console.log('partialMaintenance', partialMaintenance);

            // this.tenancyData.LeaseFirstBillAmount = (Math.round(partialRent * 100) / 100).toFixed(2);
            // this.tenancyData.LeaseFirstBillCharges = (Math.round(partialMaintenance * 100) / 100).toFixed(2);


        },

        removePaymentItem(index){
            this.paymentItems.splice(index, 1)
        },
        addPaymentItem(){
            this.paymentItems.push({})
        },
        openModal(type){

            this.curUser = null;
            this.curIndex = null;

            if(type === 'garant'){
                this.$bvModal.show('modal-add-contact')
            } else if (type === 'insurance') {
                this.documentModalType = 'insurance';
                this.$bvModal.show('modal-add-document')
            } else if(type==='tenant'){
                this.$bvModal.show('modal-add-tenant')
            } else {
                this.documentModalType = '';
                this.$bvModal.show('modal-add-document')
            }


        },
        addTenant(tenant){
            if(this.curIndex !== null){
                this.tenants[this.curIndex] = {...tenant};
                this.$bvModal.hide('modal-add-tenant');

            } else {
                this.tenants.push(tenant)
                this.tenants=[...this.tenants];
                this.$bvModal.hide('modal-add-tenant');
            }
        },
        onEditTenant(){
            //TODO
        },
        validationForm(){

            this.$refs.simpleRules.validateWithInfo().then(({isValid, errors, fields})=>{
                if(isValid){
                    const formData = new FormData();
                    if(this.curTenancyId){
                        formData.append('id', this.curTenancyId);
                    }

                    // GENERAL INFO
                    if(this.tenancyData.PropertyID.value){
                        formData.append('PropertyID', this.tenancyData.PropertyID.value);
                    } else {
                        formData.append('PropertyID', this.tenancyData.PropertyID);
                    }
                    formData.append('LeaseType', this.tenancyType);
                    formData.append('LeaseTitle', this.tenancyData.LeaseTitle);
                    formData.append('LeaseReason', this.tenancyData.LeaseReason);
                    formData.append('LeaseUsage', this.tenancyUsage);
                    formData.append('LeaseUsageActivity', this.tenancyData.LeaseUsageActivity);
                    formData.append('LeaseStartDate', this.startDate ? this.startDate : null);
                    formData.append('LeaseEndDate', this.endDate ? this.endDate : null);
                    formData.append('LeaseDurationType', this.tenancyDurationType);
                    formData.append('LeaseBillingPeriod', this.tenancyBillingPeriod);
                    formData.append('LeasePaymentMethodID', this.tenancyData.LeasePaymentMethodID);
                    formData.append('LeaseDayToBePaid', this.tenancyData.LeaseDayToBePaid);
                    formData.append('LeaseRentHC', this.tenancyData.LeaseRentHC);
                    formData.append('LeaseRentHCTaxPercent', this.tenancyData.LeaseRentHCTaxPercent);
                    formData.append('LeaseMaintenance', this.tenancyData.LeaseMaintenance);
                    formData.append('LeaseMaintenanceTaxPercent', this.tenancyData.LeaseMaintenanceTaxPercent);
                    formData.append('LeaseMaintenanceType', this.tenancyData.LeaseMaintenanceType);
                    formData.append('LeaseMaintenanceDescription', this.tenancyData.LeaseMaintenanceDescription);
                    formData.append('LeaseMonthlyAmount', this.monthlyAmount);

                    //PAYMENT ITEMS
                   if(this.paymentItems.length > 0){
                       this.paymentItems.forEach((item, index) => {
                           formData.append(`LeasePaymentItems_Amount[${index}]`, item['LeasePaymentItemsAmount']);
                           formData.append(`LeasePaymentItems_TaxPercent[${index}]`, item['LeasePaymentItemsTaxPercent']);
                           formData.append(`LeasePaymentItems_Type[${index}]`, item['LeasePaymentItemsType']);
                           formData.append(`LeasePaymentItems_Description[${index}]`, item['LeasePaymentItemsDescription']);
                       })
                   }


                    formData.append('LeaseFirstBill', this.tenancyData.LeaseFirstBill ? this.tenancyData.LeaseFirstBill : 0);
                    formData.append('LeaseFirstBillEndDate', this.firstBillEndDate ? this.firstBillEndDate : null);
                    formData.append('LeaseFirstBillAmount', this.tenancyData.LeaseFirstBillAmount);
                    formData.append('LeaseFirstBillCharges', this.tenancyData.LeaseFirstBillCharges);
                    formData.append('LeaseSecurityDeposit', this.tenancyData.LeaseSecurityDeposit);
                    formData.append('LeasePaymentCafAmount', this.tenancyData.LeasePaymentCafAmount);
                    formData.append('LeaseLateFee', this.tenancyData.LeaseLateFee);
                    formData.append('LeaseLateFeeType', this.tenancyData.LeaseLateFeeType);
                    formData.append('LeasePrepaidRent', this.tenancyData.LeasePrepaidRent);
                    formData.append('LeaseRentRevisionType', this.tenancyRentRevisionType);

                    if(this.tenancyRentRevisionType== 'index'){
                        formData.append('LeaseIndexType', this.tenancyData.LeaseIndexType);

                        const indexValueObj = this.getIndexValueObj(this.tenancyData.LeaseIndexType, this.tenancyData.LeaseIndexValue.value);

                        //TODO - check with all values when other regions/countries become available

                        const indexTypeString = this.tenancyData.LeaseIndexType == 'INDICE SANTE'
                            ? 'IndiceSante' : this.tenancyData.LeaseIndexType == 'other'
                                ? 'other' : this.tenancyData.LeaseIndexType.charAt(0) + this.tenancyData.LeaseIndexType.slice(1).toLowerCase();

                        if(indexValueObj && indexTypeString != 'other'){
                            formData.append('LeaseIndex', this.indexValueObj[indexTypeString + 'Index']);
                            formData.append('LeaseIndexTerm', this.indexValueObj[indexTypeString + 'Term']);
                            formData.append('LeaseIndexYear', this.indexValueObj[indexTypeString + 'Year']);
                        }

                        if(this.tenancyData.LeaseIndexType == 'other'){
                            formData.append('LeaseIndex', this.tenancyData.LeaseIndex);
                        }

                        formData.append('LeaseRentRevision', this.tenancyAutomaticRevision ? 1 : 0);
                    } else {
                        formData.append('LeaseRentRevisionPercent', this.tenancyData.LeaseRentRevisionPercent);
                    }


                    formData.append('LeaseRentRevisionYears', this.tenancyData.LeaseRentRevisionYears);
                    formData.append('LeaseLimitedRent', this.tenancyData.LeaseLimitedRent);
                    formData.append('LeaseLimitedZone', this.tenancyData.LeaseLimitedZone);
                    formData.append('LeaseRentHCTaxPercent', this.tenancyData.LeaseRentHCTaxPercent);
                    formData.append('LeaseRentReferenceMaj', this.tenancyData.LeaseRentReferenceMaj);
                    formData.append('LeaseRentSupplement', this.tenancyData.LeaseRentSupplement);
                    formData.append('LeaseRentSupplementDescription', this.tenancyData.LeaseRentSupplementDescription);
                    formData.append('LeaseLastLeasePeriod', this.tenancyData.LeaseLastLeasePeriod);
                    formData.append('LeaseLastRent', this.tenancyData.LeaseLastRent);
                    formData.append('LeaseLastRentPayDate', this.tenancyData.LeaseLastRentPayDate);
                    formData.append('LeaseLastRentUpdate', this.tenancyData.LeaseLastRentUpdate ? this.tenancyData.LeaseLastRentUpdate : null);
                    formData.append('LeaseRentReevaluation', this.tenancyData.LeaseRentReevaluation);
                    formData.append('LeaseRentReevaluationAmount', this.tenancyData.LeaseRentReevaluationAmount);
                    formData.append('LeaseRentReevaluationType', this.tenancyData.LeaseRentReevaluationType);

                    //TODO Tenants

                    const tenantsIdsString = this.tenants.map(tenant=>tenant.TenantID).join(',');
                    if(tenantsIdsString){
                        formData.append('LeaseTenantIds', tenantsIdsString);
                    }


                    //ADDITIONAL INFO

                    formData.append('LeaseLandlordWorksAmount', this.tenancyData.LeaseLandlordWorksAmount);
                    formData.append('LeaseLandlordWorks', this.tenancyData.LeaseLandlordWorks);
                    formData.append('LeaseTenantWorksAmount', this.tenancyData.LeaseTenantWorksAmount);
                    formData.append('LeaseTenantWorks', this.tenancyData.LeaseTenantWorks);
                    formData.append('LeaseSpecialTerms', this.tenancyData.LeaseSpecialTerms);
                    formData.append('LeaseSpecialClause', this.tenancyData.LeaseSpecialClause);
                    formData.append('LeaseComments', this.tenancyData.LeaseComments);

                    //RECEIPTS

                    formData.append('LeaseIsOtherQuittanceAddress', this.tenancyData.LeaseIsOtherQuittanceAddress ? 1 : 0);
                    formData.append('LeaseOtherQuittanceAddress', this.tenancyData.LeaseOtherQuittanceAddress);
                    formData.append('LeasePaymentDay', this.tenancyData.LeasePaymentDay);
                    formData.append('LeasePaymentCreateDay', this.tenancyData.LeasePaymentCreateDay ? this.tenancyData.LeasePaymentCreateDay : 0);
                    formData.append('LeaseMentionText', this.tenancyData.LeaseMentionText);
                    formData.append('LeaseNumbering', this.tenancyNumbering ? 1 : 0);
                    if(this.tenancyNumbering){
                        formData.append('LeaseNumberingPrefix', this.tenancyData.LeaseNumberingPrefix);
                        formData.append('LeaseUseGlobalNumbering', this.tenancyUseGlobalNumbering);
                    }
                    formData.append('LeaseAvis', this.tenancyAvis ? 1 : 0);
                    formData.append('LeasePaymentReceivedComments', this.tenancyData.LeasePaymentReceivedComments);
                    formData.append('LeasePaymentAvisComments', this.tenancyData.LeasePaymentAvisComments);

                    //OTHER SETTINGS

                    formData.append('LeaseSoldeReport', this.tenancySoldereport);
                    formData.append('LeaseRentNotifyLandlord', this.notifyLandlord ? 1 : 0);
                    formData.append('LeaseRentNotifyTenant', this.notifyTenant ? 1 : 0);
                    formData.append('LeaseAnniversaryNotifyLandlord', this.anniversaryNotifyLandlord ? 1 : 0);
                    formData.append('LeaseAnniversaryNotifyTenant', this.anniversaryNotifyTenant ? 1 : 0);

                    formData.append('LeaseActive', this.tenancyActive ? 1 : 0);


                } else {
                    let y = 1000000
                    let prevY = 1000000
                    for (const key in errors) {
                        if (errors[key].length > 0) {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: errors[key][0],
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                            const el = document.getElementById(fields[key].id)
                            prevY = el.getBoundingClientRect().top + window.scrollY - 150
                            if (prevY <= y) y = prevY
                        }
                    }
                    if (y < 1000000) {
                        window.scrollTo({ top: y, behavior: 'smooth' })
                    }
                }
            })
        },
        onEditContact(contact, type, index){

            this.curUser = {...contact};
            this.modalType = type;
            this.curIndex = index;
            this.$bvModal.show('modal-add-contact')
        },
        addContact(contact){

            if(this.curIndex != null){
                this.garants[this.curIndex] = {...contact};
                this.garants=[...this.guarantors];
                this.curIndex =  null;
                return;
            }
            this.garants.push(contact);
            this.curIndex = null;
        },
        onEditDocument(document){
            this.tenancyDocument = document;
            this.$bvModal.show('modal-add-document')
        },
        getTenancyType(value){
            this.curTenancyType = this.tenancyTypesFull.find(type=>type.LeaseTypeID == value);
            this.tenancyDurationType = false;
        },
        getIndexValues(value){

            let newValue;

            if(value=='other') {
                this.tenancyData = {
                    ...this.tenancyData,
                    LeaseIndexType: "other",
                }
                return;
            }

            if(value=='INDICE SANTE'){
                newValue = 'IndiceSante';
            } else{
                const firstChar = value.charAt(0).toUpperCase();
                const restOfValue = value.slice(1).toLowerCase()
                newValue = firstChar + restOfValue;
            }
            const newValueIndex = newValue + 'Indexes';


            if(!this.indexesObj[newValueIndex]){
                this.indexValues = [];
                return;
            }

            this.indexValues = this.indexesObj[newValueIndex].map(index=>{
                return {
                    value: index[newValue + 'ID'],
                    text: `${this.i18nT(`Trimestre`)} ${index[newValue + 'Term']},  ${index[newValue + 'Year']},  ${parseFloat(index[newValue + 'Index'] + parseFloat(index[newValue + 'Variation']))}`
                }
            });

            this.tenancyData.LeaseIndexValue = this.indexValues[0];
        },
        getIndexValueObj(value, valueID){
            let newValue;

            if(value=='other') {
                this.tenancyData = {
                    ...this.tenancyData,
                    LeaseIndexType: "other",
                }
                return;
            }

            if(value=='INDICE SANTE'){
                newValue = 'IndiceSante';
            } else{
                const firstChar = value.charAt(0).toUpperCase();
                const restOfValue = value.slice(1).toLowerCase()
                newValue = firstChar + restOfValue;
            }
            const newValueIndex = newValue + 'Indexes';


            if(this.indexesObj[newValueIndex]){
                const foundObj = this.indexesObj[newValueIndex].find(index=>index[newValue + 'ID'] == valueID);
                if(foundObj){
                    return foundObj;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        getTenancyBillingEndDate(startDate = this.startDate, billingPeriod){

            switch (billingPeriod) {
                case 'weekly':
                    this.firstBillEndDate = moment(startDate).endOf('week').format('YYYY-MM-DD');
                    break;
                case '2week':
                    this.firstBillEndDate = moment(startDate).startOf('week').add('13','days').endOf('week').format('YYYY-MM-DD');
                    break;
                case '4week':
                    this.firstBillEndDate = moment(startDate).startOf('week').add('27','days').endOf('week').format('YYYY-MM-DD');
                    break;
                case 'monthly':
                    this.firstBillEndDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
                    break;
                case '2month':
                    this.firstBillEndDate = moment(startDate).startOf('month').add('1', 'M').endOf('month').format('YYYY-MM-DD');
                    break;
                case '3month':
                    this.firstBillEndDate = moment(startDate).startOf('month').add('2', 'M').endOf('month').format('YYYY-MM-DD');
                    break;
                case '4month':
                    this.firstBillEndDate = moment(startDate).startOf('month').add('3', 'M').endOf('month').format('YYYY-MM-DD');
                    break;
                case '6month':
                    this.firstBillEndDate = moment(startDate).startOf('month').add('5', 'M').endOf('month').format('YYYY-MM-DD');
                    break;
                case '1year':
                    this.firstBillEndDate = moment(startDate).startOf('month').add('11', 'M').endOf('month').format('YYYY-MM-DD');
                    break;
            }

        }
    },
    watch:{
        startDate(newVal){
            if(!this.endDate){
                this.endDate = moment(newVal).add('3', 'years').add('1','days').format('YYYY-MM-DD')
                const daysInMonth = moment(newVal).daysInMonth();
                this.daysArr = this.getDaysInMonthArr(daysInMonth)
            } else {
                this.getTenancyDuration()
                const daysInMonth = moment(newVal).daysInMonth();
                this.daysArr = this.getDaysInMonthArr(daysInMonth)
            }
            this.getTenancyBillingEndDate(newVal, this.tenancyBillingPeriod);
        },
        endDate(){
            this.getTenancyDuration()
        },
        curTenancyType: {
            handler: function(val){
                console.log('inside curtenancytype')
                if(val.LeaseTypeReason != 1){
                    this.tenancyData.LeaseReason = '';
                    this.tenancyUsage = '0';
                    console.log(this.tenancyData.LeaseReason)
                    console.log(this.tenancyUsage)
                }
                if(val.LeaseTypeUtilisation != '2' && this.tenancyUsage != '2'){
                    this.tenancyData.LeaseUsageActivity = '';
                }
                if(!val.LeaseTypeAutoRenew){
                    this.tenancyDurationType = true;
                }
            },
            deep: true,
        },
        tenancyUsage(val){
            if(val!=2){
                this.tenancyData.LeaseUsageActivity = '';
            }
        },
        tenancyData: {
            handler: function(val){
                if(val.LeaseMaintenanceType !=2){
                    this.tenancyData.LeaseMaintenanceDescription = '';
                }

            },
            deep: true
        },
        tenancyRentRevisionType(val){
            if(val!='%'){
                this.tenancyData.LeaseRentRevisionPercent = '';
            }
        },

    }
}

</script>

<style>

.border-invalid .vs__dropdown-toggle {
    border: 0.5px solid #ea5455;
}

.dark-layout .border-invalid .vs__dropdown-toggle {
    border: 0.5px solid #ea5455;
}

.nav-item > a {
    color: inherit;
}

.nav-item > a:hover {
    color: inherit;
}


</style>